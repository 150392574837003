import { useAuthStore } from '@/stores/auth';
import { useToast, TYPE } from 'vue-toastification';
import router from '@/router';
const toast = useToast();

export const fetchWrapper = {
  get: request('GET'),
  post: request('POST'),
  put: request('PUT'),
  delete: request('DELETE'),
};

function request(method) {
  return async (url, body, withFiles, responseType = undefined) => {
    const requestOptions = {
      method,
      credentials: 'include',
      responseType,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      },
    };
    if (body) {
      if (!withFiles) requestOptions.headers['Content-Type'] = 'application/json';
      requestOptions.body = !withFiles ? JSON.stringify(body) : body;
    }

    return fetch(url, requestOptions).then((response) => {
      switch (responseType) {
        case 'blob':
          return handleResponseBlob(response);
        default:
          return handleResponseText(response);
      }
    });
  };
}

function handleResponseBlob(response) {
  return response;
}

function handleResponseText(response) {
  const authStore = useAuthStore();

  if (response.status === 304) return toast('Nada modificado.', { type: TYPE['WARNING'] });
  return response.text().then(async (text) => {
    const data = text && JSON.parse(text.toString());

    if (data.noAuth) {
      await authStore.loginByPath();
    }

    if (!response.ok) {
      let messages = null;
      if (data?.messages) messages = Object.values(data.messages).join('\n');

      let msg = messages || data?.message || response.statusText;

      if (msg === 'Unauthorized') {
        msg = 'Acesso Negado';
      }

      if (data.redirect) {
        if (data.toast) toast.info(msg.toString());
        await router.push(data.redirect);
      } else if (data.logoutByLoa) {
        authStore.logoutCallback();
        toast.error(msg.toString());
        await router.push('/');
      } else if (data.toast || data.message || data.messages) {
        toast(msg.toString(), { type: TYPE[data.toast] || 'error' });
      }
      return msg;
    } else {
      if (data.toast && data.message) {
        toast(data.message, { type: TYPE[data.toast] || 'info' });
      }
      if (data.redirect) await router.push(data.redirect);
    }

    return data;
  });
}
